import React from 'react';
import Dropdown from '../core/button/Dropdown/Dropdown';
import { copyTextToClipboard, handleKeySelect } from '../../utils/functions';
import Icon from '../core/display/Icon';
import { UserRole } from '../../types/types';

interface Props {
  asyncEnabled?: boolean;
  discipline: string;
  id?: string;
  label?: string | null;
  name: string;
  onActivate?: (courseId: string) => void;
  onArchive?: (courseId: string) => void;
  onDelete?: (courseId: string) => void;
  onLeave?: (courseId: string) => void;
  onSelect?: (courseId: string) => void;
  password?: string;
  readOnly?: boolean;
  rosterStudentCount?: number;
  role?: UserRole;
  semester: 'Spring' | 'Summer' | 'Fall' | 'Winter' | 'Full Year';
  status?: 'ACTIVE' | 'ARCHIVED';
  studentPurchaseEnabled?: boolean;
  style?: React.CSSProperties;
  timeZone?: string;
  university?: string;
  year: number;
}

function CourseCard({
  asyncEnabled,
  discipline,
  id,
  label,
  name,
  onActivate = () => undefined,
  onArchive = () => undefined,
  onDelete = () => undefined,
  onLeave = () => undefined,
  onSelect = () => undefined,
  password,
  readOnly = false,
  role,
  rosterStudentCount,
  semester,
  status,
  studentPurchaseEnabled,
  style,
  timeZone,
  university,
  year,
}: Props): JSX.Element {
  const activeClass = readOnly ? '' : ' active';

  const queryParams = new URLSearchParams({
    courseId: String(id),
    courseName: String(name),
    discipline: String(discipline),
    university: String(university),
    timeZone: String(timeZone),
    label: String(label),
    studentPurchaseEnabled: String(studentPurchaseEnabled),
    asyncEnabled: String(asyncEnabled),
  });

  const handleSelect = (e: React.MouseEvent | React.KeyboardEvent) => {
    const targetEl = e.target as HTMLElement;
    if (id && targetEl.tagName !== 'A') onSelect(id);
  };

  return (
    <div
      className={'course-card' + activeClass}
      style={style}
      tabIndex={0}
      onClick={handleSelect}
      onKeyDown={(e) => handleKeySelect(e, handleSelect)}
    >
      <div className="course-details">
        <p>
          <i>
            {semester} {year}
          </i>
        </p>
        <h2>{name}</h2>
      </div>
      <div className="label-row">
        <p>{discipline}</p>
        <p>
          <i>{label}</i>
        </p>
      </div>
      <div className="watermark-wrapper" aria-hidden>
        <Icon className="watermark" code="psychology" />
      </div>
      {rosterStudentCount !== undefined ? (
        <p className="roster-count-wrapper">
          <Icon code="person_outline" label="Roster count" />
          <span>{rosterStudentCount}</span>
        </p>
      ) : (
        ''
      )}
      {!readOnly ? (
        <div className="card-menu-wrapper">
          <Dropdown ariaLabel={`Course options for "${name}"`} minWidth="160px">
            {role === 'TEACHER' ? (
              <>
                <Dropdown.Link href={`/course/dashboard/edit?${queryParams.toString()}`} route={true}>
                  Edit Course
                </Dropdown.Link>
                <Dropdown.Link
                  onClick={() => {
                    if (password) copyTextToClipboard(password);
                  }}
                >
                  Copy Join Code to Clipboard
                </Dropdown.Link>
                <Dropdown.Link href={`/course/dashboard/copy?${queryParams.toString()}`} route={true}>
                  Copy as New
                </Dropdown.Link>
                {status === 'ACTIVE' ? (
                  <Dropdown.Link
                    onClick={() => {
                      if (id) onArchive(id);
                    }}
                  >
                    Archive Course
                  </Dropdown.Link>
                ) : (
                  <Dropdown.Link
                    onClick={() => {
                      if (id) onActivate(id);
                    }}
                  >
                    Activate Course
                  </Dropdown.Link>
                )}
                <Dropdown.Link
                  onClick={() => {
                    if (id) onLeave(id);
                  }}
                >
                  Leave Course
                </Dropdown.Link>
                <Dropdown.Link
                  onClick={() => {
                    if (id) onDelete(id);
                  }}
                >
                  Delete Course
                </Dropdown.Link>
              </>
            ) : (
              <Dropdown.Link
                onClick={() => {
                  if (id) onLeave(id);
                }}
              >
                Leave Course
              </Dropdown.Link>
            )}
          </Dropdown>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

export default CourseCard;
